import Vue from 'vue';
import { Notification } from 'element-ui';
import axios from 'axios';
import store from "../store";
var request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000
});
request.interceptors.request.use(function (config) {
  if (!store.state.app.auth_disabled && store.state.user.token) {
    config.headers['X-MOA-Token'] = store.state.user.token;
    config.withCredentials = true;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
request.interceptors.response.use(function (response) {
  var data = response.data;
  if (data.code !== 0) {
    Notification.error("".concat(data.code, ": ").concat(data.message));
    return Promise.reject(data.message);
  }
  return data['data'];
}, function (error) {
  if (error.response) {
    var data = error.response.data || {};
    if (error.response.status === 404) {
      Notification.error({
        title: '404: 目地地址不存在',
        message: data.message || ''
      });
    } else if (error.response.status === 403) {
      Notification.error({
        title: '禁止访问',
        message: data.message || ''
      });
    } else if (error.response.status === 401) {
      if (!store.state.app.auth_disabled) {
        store.dispatch('user/logout');
      } else {
        Notification.error({
          title: '没有授权',
          message: data.message || ''
        });
      }
    }
  }
  return Promise.reject(error);
});
Object.defineProperties(Vue.prototype, {
  $http: {
    get: function get() {
      return request;
    }
  }
});
export default request;